<template>
  <div>
    <div class="condition">
      <el-input
        class="condition_input content_item"
        placeholder="请输入设备编码或关键字"
      ></el-input>
      <el-select
        v-model="repairsName"
        class="content_state content_item"
        placeholder="报修类型"
      >
        <el-option
          v-for="item in repairsName"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dispose"
        class="content_state content_item"
        placeholder="处理状态"
      >
        <el-option
          v-for="item in repairsName"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <br />
      <span class="warning_time_title">报警时间</span>
      <el-date-picker
        style="margin-top:20px"
        prefix-icon="el-icon-date"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="warningStartTime"
        type="datetime"
        placeholder="选择开始日期时间"
      >
      </el-date-picker>
      <span style="padding:4px">-</span>
      <el-date-picker
        class="content_item"
        prefix-icon="el-icon-date"
        v-model="warningEndTimes"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择结束日期时间"
      >
      </el-date-picker>
      <span class="warning_time_title">处理时间</span>
      <el-date-picker
        prefix-icon="el-icon-date"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="disposeStartTime"
        type="datetime"
        placeholder="选择开始日期时间"
      >
      </el-date-picker>
      <span style="padding:4px">-</span>
      <el-date-picker
        class="content_item"
        prefix-icon="el-icon-date"
        v-model="disposeEndTimes"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择结束日期时间"
      >
      </el-date-picker>
      <el-button class="query content_item">查询</el-button>
      <el-button class="import content_item">删除</el-button>
      <el-button class="import content_item">导出</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="店名" width="180">
        </el-table-column>
        <el-table-column prop="name" label="设备型号" width="180">
        </el-table-column>
        <el-table-column prop="address" label="资产编码"> </el-table-column>
        <el-table-column prop="IMEI" label="资产编码"> </el-table-column>
        <el-table-column prop="address" label="报警类型"> </el-table-column>
        <el-table-column prop="address" label="报警时间"> </el-table-column>
        <el-table-column prop="address" label="处理状态"> </el-table-column>
        <!--                <el-table-column-->
        <!--                        prop="assetCode"-->
        <!--                        label="操作"-->
        <!--                >-->
        <!--                    <template slot-scope="scope">-->
        <!--                        <el-button type="text" class="edit el-icon-edit" size="small" @click="edit(scope.row)">-->
        <!--                            编辑-->
        <!--                        </el-button>-->
        <!--                        <i class="app-operation-line"/>-->
        <!--                        <el-button type="text"-->
        <!--                                   size="small"-->
        <!--                                   style="color: #F56C6C;font-size: 14px"-->
        <!--                                   @click="remove(scope.row)">删除-->
        <!--                        </el-button>-->
        <!--                    </template>-->
        <!--                </el-table-column>-->
      </el-table>
      <div class="app-page-container">
        <span @click="first">首页</span>
        <el-pagination
          @current-change="handleCurrentChange"
          layout="pager"
          :hide-on-single-page="false"
          :page-size="12"
          :current-page="currentPage"
          :total="recordCount"
        >
        </el-pagination>
        <span @click="last">尾页</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      pages: 1,
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      disposeStartTime: null,
      disposeEndTimes: null,
      dispose: "",
      warningStartTime: null,
      warningEndTimes: null,
      repairsName: "",
      repairsItme: [],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ]
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getLisr();
    },
    //分页首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      // let data = {curPage: 1, limit: 12};
    },
    //分页最后一页
    last() {
      this.tableData = [];
      // let data = {curPage: this.pages, limit: 12};
    }
  }
};
</script>

<style scoped>
.content_state {
  width: 150px;
}
.warning_time_title {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: -0.04px;
  margin-right: 5px;
}
</style>
